<template>
  <v-app-bar
    v-model="appbar"
    dense
    elevation="0"
    color="white"
    app
    class="font-weight-light"
  >
    <v-app-bar-nav-icon
      dense
      :color="bar_color"
      @click="drawer"
    />
    <v-toolbar-title class="text-primary">
      {{ $route.name }}
    </v-toolbar-title>
    <v-spacer />
    <span class="caption text-primary">Hi, {{LOGIN_USER()}}</span>
    <v-icon
      dense
      right
      color="error"
      @click="logout"
    >
      mdi-logout
    </v-icon>
  </v-app-bar>
</template>
<script>
import {
  mapMutations
} from 'vuex'
  export default {
    data: () => ({
      bar_color: "secondary"
    }),
    computed: {
      appbar() {
        return this.$store.state.appbar
      },
      
    },
    methods: { 
      ...mapMutations(['setLoggedIn', 'setAppBar', 'setDrawer']),
      drawer() {
        this.$store.state.drawer != true?this.setDrawer(true):this.setDrawer(false)
        this.bar_color != "primary"?this.bar_color="primary":this.bar_color="secondary"
      },
      logout() {
        this.setDrawer(false)
        this.setAppBar(false)
         this.setLoggedIn(false)
         this.$router.push('/')
      }

    }
  }
</script>