<template>
  <v-dialog
    v-model="dailog"
    width="540"
    persistent
  >
  <v-form  
              
              ref="form"
              v-model="valid"
              autocomplete="off"
              lazy-validation
            >
    <v-card >
      <v-card-title
        class="text-h6"
        dense
      >
        <v-icon left color="success">
          mdi-cog
        </v-icon> Settings 
        <v-spacer />
        <v-btn
          color="success"
          text
          dense
          @click="emitToParent('close')"
        >
          Close
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="elevation-0 pa-6">
      
         
              <v-text-field
                  v-model="setting.description"
                  :rules="nameRules"
                    required
                    label="Description"
                    color="success"
                    dense
                    outlined
                  />
                  <v-autocomplete
                  v-model="setting.value"
                  :rules="nameRules"
                    required
                    label="Status"
                    color="success"
                    dense
                    :items="['TABON PORT', 'JETTY PORT']"
                    outlined
                  />
      
           

            
      </v-card-text>
      <v-card-actions>
                  <v-spacer/>
                  <v-btn small tile color="success" @click="validate()">{{state=='new'?'Add': 'Save'}}</v-btn>
                </v-card-actions>
    </v-card>
  </v-form> 
  </v-dialog>
</template>
<script>
import {
  mapMutations
} from 'vuex'
  export default {
    props: {
    show: {
      type: Boolean,
      default: false
    },
    state: {
      type: String,
      default: "new"
    },
    data: {
      type: Object,
      default: ()=>{}
    }
    
    },
    data: () => ({
      valid: true,
      loading: false,
      roles: ["Verifier", "Operator", "settinging", "Business Owner", "Administrator"],
        dailog: false,
        setting: {},
        nameRules: [
        v => !!v || 'Field is required'
      ],
      emailRule: [
        v => !!v || "Field is required",
        v => /.+@.+\..+/.test(v) || "Invalid email",
      ],
      changepass: false,
      email: "", 
      password: "", 
      password2: "", 
    }),
    watch: {
        show(v) {
          v?this.dailog=true:this.dailog=false
          if(v) {
            this.state != 'new'? this.setting = this.data:this.setting={}
          }
        }
    },
    created() {
    },
    computed:{
  
},
methods: {
  ...mapMutations(['setLoggedIn', 'setAppBar']),
  emitToParent (action) {
        this.$emit('DialogEvent', {action: action})
      },

      validate () {
        if (this.$refs.form.validate()) {
          this.update()
        }
         
      },

 update() {
     this.$http.post("settings/update",this.setting).then(response => {
      response.data.status? this.VA_ALERT('success', response.data.message): this.VA_ALERT('error', response.data.message)
      this.emitToParent('close')
     }).catch(e => {
         this.NETWORK_ERROR(e)
    })
 }
},
  }
</script>
