<template>
  <v-dialog
    v-model="dailog"
    width="420"
    persistent
  >
    <v-card>
      <v-card-title
        class="text-h6"
        dense
      >
        <v-icon left>
          mdi-account-tie
        </v-icon>Guest Details:
        <v-spacer />
        <v-icon   @click="emitToParent('close')">mdi-close</v-icon>
       
      </v-card-title>
      <v-divider />
      <v-card-text class="mt-4">
        <v-form>
          <v-row>
            <v-col cols="12">

              STAYIN-IN:  {{hotel.name}}
             <v-divider class="mt-1 mb-4"/>
              Booking Ref. No: {{booking.ref_no}}
             <v-divider class="mt-1 mb-4"/>

             CHECKIN:  {{$moment(booking.checkin_date).format("MMM d, YYYY")}}
             <v-divider class="mt-1 mb-4"/>
             CHECKOUT: {{$moment(booking.checkout_date).format("MMM d, YYYY")}}
             <v-divider class="mt-1 mb-4"/>
              <v-form  
              ref="form2"
              v-model="valid2"
              lazy-validation
            >
     
                  <v-row>
                  
                    <v-col cols="6">
                      <v-text-field
                      :disabled="locked"
                      :rules="nameRules"
                      v-model="guest.firstname"
                      hide-details
                      dense
                        label="First Name"
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                      hide-details
                      dense
                      :disabled="locked"
                      :rules="nameRules"
                        label="Last Name"
                        v-model="guest.lastname"
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-autocomplete
                      :disabled="locked"
                      hide-details
                      v-model="guest.gender"
                      :items="['Male', 'Female']"
                      label="Gender"
                      dense
                    />
                    </v-col>
                    <v-col cols="6">
                    <v-text-field
                    :disabled="locked"
                    hide-details
                    dense
                    label="Age"
                    v-model="guest.age"
                    type="number"
                  />
                    </v-col>  
                    <v-col cols="4">
                      <v-checkbox
                      :disabled="locked"
                      hide-details
                    dense
              v-model="guest.senior"
              label="Senior Citizen?"
              color="success"
              value="yes"
            ></v-checkbox>
                   
                    </v-col>  
                    <v-col cols="4">
                      <v-checkbox
                      :disabled="locked"
                      hide-details
                    dense
              v-model="guest.pwd"
              label="PWD?"
              color="success"
              value="yes"
            ></v-checkbox>
          </v-col>  
            <v-col cols="4">
                      <v-checkbox
                      :disabled="locked"
                      hide-details
                    dense
              v-model="guest.foc"
              label="FOC"
              color="success"
              value="yes"
            ></v-checkbox>
                   
                    </v-col>
                    <v-col cols="6">
                      <v-autocomplete
                      :disabled="locked"
                      hide-details
                          v-model="guest.class"
                          :rules="nameRules"
                          :items="['DOMESTIC', 'FOREIGN', 'OFW']"
                          label="Classification"
                          @change="setCountry()"
                          dense
              />  
                    </v-col>
                  <v-col cols="6">
                      <v-autocomplete
                      :disabled="locked"
                      hide-details
                      :rules="nameRules"
                      :items="countries"
                      v-model="guest.country"
                      label="Country"
                      item-value="name"
                      item-text="name"
                      
                      dense
                    />
                    </v-col>
                    <v-col cols="6">
                      <v-autocomplete
                      :disabled="locked"
                      v-if="guest.class!='FOREIGN'"
                      :items="regions"
                      v-model="guest.region"
                      label="Region"
                      item-value="name"
                      item-text="name"
                      @change="setProvinces()"
                      dense
                    />
                    </v-col>
                    <v-col cols="6">
                      <v-autocomplete
                      :disabled="locked"
                      hide-details
                      v-if="guest.class!='FOREIGN'"
                      :items="provinces"
                      v-model="guest.province"
                      label="State/Province"
                      item-value="name"
                      item-text="name"
                      @change="setMunicipalities()"
                      dense
                    />
                 
                  </v-col>
                  <v-col cols="12">
                    <v-autocomplete
                    :disabled="locked"
                      hide-details
                      v-if="guest.class!='FOREIGN'"
                      :items="municipalities"
                      v-model="guest.city"
                      label="City/Municipality"
                      dense
                    />
                 
                </v-col>
                  </v-row>
             </v-form>
             
            </v-col>
          </v-row>
        </v-form>
        <v-divider/>
      </v-card-text>
      <v-card-actions>
                  <v-icon @click="edit()" color="warning">mdi-{{locked?'lock':'lock-open-variant'}}</v-icon>
                   <v-spacer/>
                   <v-btn small tile color="primary"  :disabled="locked" :loading="gloading" @click="validateGuest()" >{{ state=='new'?'Add':'Save' }}</v-btn>
               </v-card-actions>

    </v-card>
  </v-dialog>
</template>
<script>
import {
  mapMutations
} from 'vuex'
  export default {
    props: {
    show: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: () =>{}
    },
    
    state: {
      type: String,
      default: ""
    }
    
    },
    data: () => ({
        valid: true,
        valid2: true,
        loading: false,
        gloading: false,
        dailog: false,
        from_menu: false,
        to_menu: false,
        guests: [],
        regions: [],
        provinces: [],
        municipalities:[],
        guest:{},
        from_date: "",
        locked: true,
        to_date: "",
        booking:{},
        hotel: {},
        nameRules: [
        v => !!v || 'Field is required'
      ],
      guestRules: [
        v => !!v || 'Field is required'
      ],
    }),
    watch: {
        show(v) {
          v?this.dailog=true:this.dailog=false
          this.locked= true
          if(v) {
            this.guest = this.data
            this.viewBooking()
            this.setCountry()
            this.setProvinces()
            this.setMunicipalities()
          } else {
            this.guest = {}
          }
        }
    },
    created() {
    },
    computed: {
      countries() {
        return this.$countries
      }
    },
    methods: {
      emitToParent (action) {
        this.$emit('DialogEvent', {action: action})
      },
      edit(){
        this.locked = !this.locked
      },
      setProvinces() {
      var obj = this.$philippines;
      Object.values(obj).forEach((val) => {
        if (this.guest.region == val.region_name) {
          this.provinces = Object.keys(val.province_list);
        }
      });
    },
    setMunicipalities() {
      var obj = this.$philippines;
      Object.values(obj).forEach((val) => {
        if (this.guest.region == val.region_name) {
          Object.entries(val.province_list).forEach(([key, value]) => {
            if (key == this.guest.province) {
              this.municipalities = Object.keys(value.municipality_list);
            }
          });
        }
      });
    },
      initRegions() {
      var items = []
      var entries = Object.entries(this.$philippines);
      entries.forEach(([key, value]) => {
        items.push({key:key, name:value.region_name});
      });
      this.regions = items
    },
      setCountry(){
        console.log("class", this.guest.class)
        if(this.guest.class != "FOREIGN") {
          this.guest.country = 'Philippines'
          this.initRegions()
        }
      
      },
     
      validateGuest() {
        if (this.$refs.form2.validate()) {
          this.updateGuest()
        }
      },

      clearForm(){
        this.$refs.form2.reset()
        this.guest = {}
        
      },
      viewBooking() {
        this.$http.post('booking/view', {ref_no: this.guest.ref_no}).then(response => {
            response.data.booking?this.booking = response.data.booking:this.booking={}
            response.data.hotel?this.hotel = response.data.hotel:this.hotel={}
        }).catch(e => {
        console.log(e)
        })
      },
      updateGuest() {
        this.gloading = true
        this.guest.age = this.$IsNum(this.guest.age)
        this.$http.post('guest/update', this.guest).then(response => {
            this.gloading = false
            response.data.status? this.VA_ALERT('success', response.data.message): this.VA_ALERT('error', response.data.message)
            this.emitToParent("close")
        }).catch(e => {
        console.log(e)
        })
      },
      
    },
  }
</script>
