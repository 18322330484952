<template>
  <v-dialog
    v-model="dailog"
    width="870"
    persistent
  >
    <v-card>
      <v-card-title
        class="text-h6"
        dense
      >
        <v-icon 
        color="info"
        left>
          mdi-office-building-marker
        </v-icon> Hotel's Info
        <v-spacer />
        <v-btn
          color="success"
          text
          dense
          @click="emitToParent('close')"
        >
          Close
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="elevation-0 animate__animated animate__fadeInLeft">
        <v-form  
              
              ref="form"
              v-model="valid"
              autocomplete="off"
              lazy-validation
              style="width: 100%; margin: auto;"
            >
              <v-row>
                <v-col cols="6">
                <div class="mt-4 mb-2">
                  Business Details
                <v-divider/>
                </div>
              <v-text-field
                  v-model="hotel.name"
                    label="Name"
                    color="success"
                    dense
                    prepend-inner-icon="mdi-domain"
                    outlined
                  />
                  <v-text-field
                  v-model="hotel.trade_name"
                    label="Trade Name"
                    color="success"
                    dense
                    prepend-inner-icon="mdi-registered-trademark"
                    outlined
                    required
                  />
                  <v-select 
                    v-model="hotel.business_line"
                    label="Business Line"
                    :items="['RH1','RH2','RH3','RH4']"
                    color="success"
                    dense
                    prepend-inner-icon="mdi-domain"
                    outlined
                    required
                  />

                  <v-text-field
                  v-model="hotel.license_no"
                    label="Business License No."
                    color="success"
                    dense
                    prepend-inner-icon="mdi-license"
                    outlined
                    required
                  />
                  <div class="mb-4">
                  
                  <span><v-icon left  color="primary">mdi-format-list-checks</v-icon> LGU Ref. No.: {{hotel.ref_no}}</span>
                  
                  <v-divider class="ma-2"/>
                </div>
              </v-col>
              <v-col cols="6">
                  <div class="mt-4 mb-2">
                    Contact Person 
                <v-divider/>
              </div>
              <v-text-field
                  v-model="account.firstname"
                    required
                    label="First Name"
                    color="success"
                    dense
                    prepend-inner-icon="mdi-account-tie"
                    outlined
                  />
                  <v-text-field
                  v-model="account.lastname"
                    required
                    label="Last Name"
                    color="success"
                    dense
                    prepend-inner-icon="mdi-account-tie"
                    outlined
                  />
                  <v-text-field
                  v-model="account.mobile_no"
                    required
                    label="Contact No."
                    color="success"
                    dense
                    prepend-inner-icon="mdi-cellphone-basic"
                    outlined
                  />  
                  <v-text-field
                  v-model="account.email"
                    required
                    label="Email"
                    color="success"
                    dense
                    prepend-inner-icon="mdi-account"
                    outlined
                  />
                  <div class="mt-8">
                   
                  <div v-if="hotel.status == 'Verified'" class="text-h4 text-success font-weight-thin text-center font-italic">
                    <v-icon 
                    left
                    color="success"
                    size="64"
                    >mdi-check-decagram</v-icon>
                    {{hotel.status}}
                  </div>
                  <v-btn v-else block large color="success" :loading="loading" dark @click="hotel.status = 'Verified', updateHotel()">
                    VERIFIED
                  </v-btn>
                </div>
                </v-col>
              </v-row>
             </v-form> 
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn x-small text color="error" @click="hotel.status = 'Deactivated', updateHotel()">DEACTIVATE</v-btn>
      </v-card-actions>
        
    </v-card>
  </v-dialog>
</template>
<script>
import {
  mapMutations
} from 'vuex'
  export default {
    props: {
    show: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: ()=>{}
    }
    
    },
    data: () => ({
      valid: true,
      loading: false,
        dailog: false,
        hotel: {},
        credintial: {  email: "", password: ""},
        account: {}
    }),
    watch: {
        show(v) {
          v?this.dailog=true:this.dailog=false
          if(v) {
            this.hotel = this.data
            this.getAccount()
          }
        }
    },
    created() {
    },
    methods: {
      emitToParent (action) {
        this.$emit('DialogEvent', {action: action})
      },
      updateHotel() {
        this.loading = true
         this.$http.post("hotel/update",this.hotel).then(response => {
          this.loading=false
          response.data.status? this.VA_ALERT('success', response.data.message): this.VA_ALERT('error', response.data.message)
          this.emitToParent("close")
         }).catch(e => {
             this.NETWORK_ERROR(e)
        })
     },
      getAccount() {
         
         this.$http.post("user/details",{id: this.hotel.owner_id}).then(response => {
           response.data.status?this.account = response.data.account:this.account = {}
          
         }).catch(e => {
             this.NETWORK_ERROR(e)
        })
     }
    },
  }
</script>
